import { API } from "../config";

export const getAllProductbygenerics = () =>{
    return fetch (`${API}/getallproductbygenerics`)
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

export const addProductbygeneric = (productbygeneric, token) => {
    return fetch(`${API}/addproductbygeneric`,{
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        },
        body:productbygeneric
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}