import { API } from "../config";

export const getAllTeams = () =>{
    return fetch (`${API}/getallteams`)
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

export const addTeam = (team, token) => {
    return fetch(`${API}/addteam`,{
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        },
        body:team
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}
