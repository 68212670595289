import React from 'react'

const AdminFooter = () => {
  return (
    <>
    <div className='container-fluid bg-body-secondary'>
          <div className="d-flex flex-column flex-sm-row justify-content-center py-2 my-2  border-0">
            <p>&copy; 2024 Dakali Industrial Marketing Pte. Ltd. All rights reserved.</p>
          </div>
          </div>
    </>
  )
}

export default AdminFooter