import React from 'react';
import AdminSidebar from '../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../layouts/Admin/AdminFooter'

const AdminDashboard = () => {
  return (
    
    <>
    {/* <Navbar/> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <AdminSidebar menu/>
            </div>
            <div className='col-md-9 p-5'>
                <h2>Welcome To Admin Dashboard</h2>
            </div>
        </div>
        <AdminFooter/>
    </div>
    
    </>
  )
}

export default AdminDashboard