import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { API } from '../../../../config'
import { Link } from 'react-router-dom'
import { getAllProductbygenerics } from '../../../../api/productbygenericapi'

const AdminProductbygeneric = () => {
    const [productbygenerics, setProductbygenerics] = useState([])

    useEffect(() => {
        getAllProductbygenerics()
            .then(data => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    setProductbygenerics(data)
                }
                        })
    }, [])
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminSidebar productbygeneric/>
                    </div>
                    <div className='col-md-9 p-5'>
                        <h2>Productsbygenerics</h2>
                        <table className='table text-center table-hover table-bordered'>
                            <thead className='table-dark'>
                                <tr>
                                    <td>S.No.</td>
                                    <td>Productbygeneric Title</td>
                                    <td>Productbygeneric Image</td>
                                    <td>Productbygeneric Description</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    productbygenerics && productbygenerics.map((productbygeneric,i)=>{
                                        return <tr key={productbygeneric._id}>
                                                    <td>{i+1}</td>
                                                    <td>{productbygeneric.productbygeneric_title}</td>
                                                    <td>
                                                        <img src={`${API}/${productbygeneric.productbygeneric_image}`} alt = {productbygeneric.productbygeneric_image} style={{height:"80px",width:'150px'}}/>
                                                    </td>
                                                    <td>{productbygeneric.productbygeneric_description}</td>
                                                    <td>
                                                        <div className='btn btn-group'>
                                                            <button className='btn btn-warning'>Update</button>
                                                            <button className='btn btn-danger'>Remove</button>
                                                        </div>
                                                    </td>

                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        <Link to ='/admin/productbygeneric/add'>Add New Productbygeneric</Link>
                    </div>
                </div>
                <AdminFooter />
            </div>

        </>
    )
}

export default AdminProductbygeneric