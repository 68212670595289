import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { getAllCustomers } from '../../../../api/customerapi'
// import { API } from '../../../../config'
import { Link } from 'react-router-dom'

const AdminCustomer = () => {
    const [customers, setCustomers] = useState([])

    useEffect(() => {
        getAllCustomers()
            .then(data => {
                if (data.error) {
                    console.log(data.error)
                }
                else {
                    setCustomers(data)
                }
                        })
    }, [])
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminSidebar customers />
                    </div>
                    <div className='col-md-9 p-5'>
                        <h2>Customers</h2>
                        <table className='table text-center table-hover table-bordered'>
                            <thead className='table-dark'>
                                <tr>
                                    <td>S.No.</td>
                                    <td>Customer Name</td>
                                    <td>Customer Email</td>
                                    <td>Customer Description</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customers && customers.map((customer,i)=>{
                                        return <tr key={customer._id}>
                                                    <td>{i+1}</td>
                                                    <td>{customer.customer_name}</td>
                                                    <td>
                                                    {customer.customer_email}
                                                    </td>
                                                    <td>{customer.customer_description}</td>
                                                    <td>
                                                        <div className='btn btn-group'>
                                                            <button className='btn btn-warning'>Update</button>
                                                            <button className='btn btn-danger'>Remove</button>
                                                        </div>
                                                    </td>

                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        <Link to ='/admin/customer/add'>Add New Customer</Link>
                    </div>
                </div>
                <AdminFooter />
            </div>

        </>
    )
}

export default AdminCustomer