
import React, { useState } from 'react';
import AdminSidebar from '../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../layouts/Admin/AdminFooter'
import { addMenu } from '../../../api/menuapi';
import { Link } from 'react-router-dom';


const AddMenu = () => {
    let [menu_name, setMenuName] = useState('')
    // let {token} = isAuthenticted()
    let [error, setError] = useState('')
    let [success, setSuccess] = useState(false)

    const handleSubmit = e=> {
        e.preventDefault()
        // addMenu(menu_name, token)
        addMenu(menu_name)
        .then(data=>{
            if(data.error){
                setError(data.error)
                setSuccess(false)
            }
            else{
                setSuccess(true)
                setError('')
                setMenuName('')
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>Menu Added Successfully.</div>
        }
    }
    
    return (
        <>
{/* <Navbar/> */}
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3'>
                        <AdminSidebar menu />
                    </div>
                    <div className='col-md-9 p-5'>
                        <h2>Add Menu</h2>
                        {showSuccess()}
                        {showError()}
                        <form className='p-5 mt-3 shadow-lg w-50'>
                            <label htmlFor='menu_name'>Menu Name</label>
                            <input type={'text'} id='menu_name' className='form-control' onChange={e=>setMenuName(e.target.value)} value={menu_name}/>
                            <button className='btn btn-warning w-100 mt-3' onClick={handleSubmit}>Add New Menu</button>

                            <Link to = '/admin/menu'>Go back</Link>
                        </form>
                    </div>
                </div>
                <AdminFooter />
            </div>

        </>
    )
}




export default AddMenu