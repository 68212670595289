import { API } from "../config";

export const getAllmenus = () =>{
    return fetch(`${API}/getallmenus`)
    .then(res=>res.json())
    .catch(err=>console.log(err))
};

//add menu
export const addMenu = (menu_name, token) => {
    return fetch(`${API}/addmenu`,{
        method:"POST",
        headers:{
            "Content-Type" : "application/json",
            "Authorization" : `Bearer ${token}`
        },
        body: JSON.stringify({menu_name})
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

//to get menu details
export const getMenuDetails = (id) => {
    return fetch(`${API}/menudetails/${id}`)
    .then(res=>res.json())
    .catch(err=>console.log(err))

}

//to update menu
export const updateMenu = (id, menu_name, token) => {
    return fetch(`${API}/updatemenu/${id}`,{
        method: "PUT",
        headers:{
            "Content-Type" : "application/json",
            "Authorization" : `Bearer ${token}`
        },
        body: JSON.stringify({menu_name})
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}
//to delete menu
export const deleteMenu = (id, token) => {
    return fetch(`${API}/deletemenu/${id}`,{
        method: "DELETE",
        headers: {
            "Content-Type":"application/json",
            Authorization: `Bearer ${token}`
        }
})
.then(res=>res.json())
.catch(err=>console.log(err))
}
