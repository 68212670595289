import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../layouts/Admin/AdminFooter'
import { deleteMenu, getAllmenus } from '../../../api/menuapi'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'

//import { getAllMenus } from '../../../api/menuapi';

const AdminMenu = () => {
    const [menus, setMenus] = useState([])
    // const {token} = isAuthenticated()
    const [success, setSuccess] = useState(false)

    useEffect(()=>{
        getAllmenus()
        .then(data=>{
            if(data.error){
                console.log(data.error)
            }
            else{
                setMenus(data)
            }
        })
},[success])

const handleDelete = id => e => {
    e.preventDefault()
    setSuccess(false)
    swal("Delete Menu","Are you sure you want to delete this menu?", {
        buttons: {
          Cancel: "Cancel",
           Delete: "Delete"
        },
        icon:"warning"
      })
      .then((value) => {
        switch (value) {
       
         
       
          case "Cancel":
            swal("Cancel", "Delete Canceled!", "warning");
            break;
         case "Delete":
            // deleteMenu(id, token)
            deleteMenu(id)
            .then(data=>{
                if(data.error){
                    swal("Error!", data.error, "error");
                }
                else{
                    setSuccess(true)
                    swal("Success !", data.msg, "success");
                }
            })  
            .catch(
                swal("Something went wrong!")
            ) 
            break
       
          default:
            swal("Something went wrong!");
        }
      });
}
  return (
    <>
    {/* <Navbar/> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <AdminSidebar menu/>
            </div>
            <div className='col-md-9 p-5'>
                <h2>Menu</h2>
                <table className='table w-75 table-hover text-center table-bordered align-middle'>
                    <thead>
                        <tr className='table-dark'>
                            <th>S.No.</th>
                            <th>Menu Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                    menus && menus.map((menu,i) =>{
                        return <tr key={menu._id}>
                            <td>{i+1}</td>
                            <td>{menu.menu_name}</td>
                            <td>
                                <div className='btn-group'>
                                    <Link to={`/admin/menu/update/${menu._id}`} className='btn btn-warning'>Update</Link>
                                    <button className='btn btn-danger' onClick={handleDelete(menu._id)}>Delete</button>
                                </div>
                            </td>
                        </tr>
                    }

                    )
                }
                    </tbody>
                </table>
                    <Link to = '/admin/menu/add'>Add new Menu</Link>
                
            </div>
        </div>
        <AdminFooter/>
    </div>
    
    </>
  )
}

export default AdminMenu