import { API } from "../config";

export const getAllCustomers = () =>{
    return fetch (`${API}/getallcustomers`)
    .then(res=>res.json())
    .catch(err=>console.log(err))
}

export const addCustomer = (customer, token) => {
    return fetch(`${API}/addcustomer`,{
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        },
        body:customer
    })
    .then(res=>res.json())
    .catch(err=>console.log(err))
}