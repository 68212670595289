import React, { useEffect, useState } from 'react';
import AdminSidebar from '../../../layouts/Admin/AdminSidebar'
import AdminFooter from '../../../layouts/Admin/AdminFooter'
import { addCustomer } from '../../../../api/customerapi';

const AddCustomer = () => {
    const [customer, setCustomer] = useState({
        customer_name: '',
        customer_email: '',
        customer_description: '',
        error:'',
        success:false,
        formdata: ''
    })

    // const {token} = isAuthenticated()
    const {customer_name,customer_email,customer_description,error,success,formdata} = customer

    useEffect(()=>{
        setCustomer({...customer, formdata: new FormData})
    },[])

    const handleChange = name => e => {
        let value
        if(name === 'customer_name'){
            value = e.target.files[0]
        }
        else{
            value = e.target.value
        }
        setCustomer({...customer, [name]: value})
        formdata.set(name, value)
    }

    const handleSubmit = e => {
        e.preventDefault()
        // addCarousel(formdata, token)
        addCustomer(formdata)
        .then(data=>{
            if(data.error){
                setCustomer({...customer, error:data.error})
            }
            else{
                setCustomer({success:true, customer_name:'',customer_email: '', customer_description: '', formdata: new FormData, error:''})
            }
        })
    }
    const showError = () => {
        if(error){
            return <div className='alert alert-danger'>{error}</div>
        }
    }

    const showSuccess = () => {
        if(success){
            return <div className='alert alert-success'>Customer Added Successfully.</div>
        }
    }

  return (
    
    <>
    {/* <Navbar/> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-3'>
                <AdminSidebar customers/>
            </div>
            <div className='col-md-9 p-5'>
                <h2>Add New Customer.</h2>
                {showError()}
                {showSuccess()}
                        
                <form>
                    <label htmlFor='customer_name'>Customer Name</label>
                    {/* <input type={'text'} id='customer_name' className='form-control' onChange={e=>setCustomerName(e.target.value)} value={customer_name}/> */}
                    {/* <input type='text' className='form-control mb-1' id='carousel-title' onChange={handleChange('carousel_title')} value={carousel_title}/> */}
                    <input type='text' className='form-control mb-1' id='customer-name'  onChange={handleChange('customer_name')} value={customer_name}/>
                    <label htmlFor='customer_email'>Customer Email</label>
                    <input type='email' className='form-control mb-1' id='customer_email' onChange={handleChange('customer_email')} value={customer_email}/>
                    <label htmlFor='customer_description'>Customer Description</label>
                    <textarea className='form-control mb-1' rows={5} id='customer_description'  onChange={handleChange('customer_description')} value={customer_description}></textarea>

                    <button className='btn btn-warning w-100' onClick={handleSubmit}>Add Customer</button>
                </form>
            </div>
        </div>
        <AdminFooter/>
    </div>
    
    </>
  )
}

export default AddCustomer