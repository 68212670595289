import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './components/pages/Home'
import Aboutus from './components/pages/Aboutus'
import Team from './components/pages/Team'
import Contactus from './components/pages/Contactus'
import Products from './components/Products'
import Brands from './components/Brands'
import AdminDashboard from './components/pages/Admin/AdminDashboard'
import AdminMenu from './components/pages/Admin/AdminMenu'
import AddMenu from './components/pages/Admin/AddMenu'
import UpdateMenu from './components/pages/Admin/UpdateMenu'
import AdminCarousel from './components/pages/Admin/Carousel/AdminCarousel'
import AddCarousel from './components/pages/Admin/Carousel/AddCarousel'
import AdminBrand from './components/pages/Admin/Brands/AdminBrand'
import AddBrand from './components/pages/Admin/Brands/AddBrand'
import AdminProductbygeneric from './components/pages/Admin/Productbygeneric/AdminProductbygeneric'
import AddProductbygeneric from './components/pages/Admin/Productbygeneric/AddProductbygeneric'
import AdminCustomer from './components/pages/Admin/Customer/AdminCustomer'
import AddCustomer from './components/pages/Admin/Customer/AddCustomer'
import AdminProductbyindustry from './components/pages/Admin/Productbyindustry/AdminProductbyindustry'
import AddProductbyindustry from './components/pages/Admin/Productbyindustry/AddProductbyindustry'
import AdminTeam from './components/pages/Admin/Team/Adminteam'
import AddTeam from './components/pages/Admin/Team/Addteam'
import AdminAbout from './components/pages/Admin/About/AdminAbout'
import AddAbout from './components/pages/Admin/About/AddAbout'

const Myroutes = () => {
  return (
    <BrowserRouter>
    <Routes>
        <Route path='/' element = {<Home/>}/>
        <Route path='/aboutus' element = {<Aboutus/>}/>
        <Route path='/team' element = {<Team/>}/>
        <Route path='/product' element = {<Products/>}/>
        <Route path='/contact' element = {<Contactus/>}/>
        <Route path='/brands' element = {<Brands/>}/>



        <Route path='/admin/dashboard' element = {<AdminDashboard/>}/>
        <Route path='/admin/menu' element = {<AdminMenu/>}/>
        <Route path='/admin/menu/add' element = {<AddMenu/>}/>
        <Route path='/admin/menu/update/:id' element = {<UpdateMenu/>}/>

        <Route path='/admin/carousel' element = {<AdminCarousel/>}/>
        <Route path='/admin/carousel/add' element = {<AddCarousel/>}/>

        <Route path='/admin/about' element = {<AdminAbout/>}/>
        <Route path='/admin/about/add' element = {<AddAbout/>}/>

        <Route path='/admin/brand' element = {<AdminBrand/>}/>
        <Route path='/admin/brand/add' element = {<AddBrand/>}/>

        <Route path='/admin/productbygeneric' element = {<AdminProductbygeneric/>}/>
        <Route path='/admin/productbygeneric/add' element = {<AddProductbygeneric/>}/>

        <Route path='/admin/productbyindustry' element = {<AdminProductbyindustry/>}/>
        <Route path='/admin/productbyindustry/add' element = {<AddProductbyindustry/>}/>

        <Route path='/admin/customer' element = {<AdminCustomer/>}/>
        <Route path='/admin/customer/add' element = {<AddCustomer/>}/>

        <Route path='/admin/team' element = {<AdminTeam/>}/>
        <Route path='/admin/team/add' element = {<AddTeam/>}/>

        
        
    </Routes>
    
    
    </BrowserRouter>
  )
}

export default Myroutes